import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import { Button, CardActionArea, CardActions } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import { Typography, createTheme, ThemeProvider } from "@mui/material";
import { DateTime, IntlProvider } from "react-intl-datetime-format";
import { Link as RouterLink, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Link from '@mui/material/Link';
import { GoogleMap, LoadScript, MarkerF, Marker} from '@react-google-maps/api';
import Toolbar from '@mui/material/Toolbar';
import BackToTop from '../components/ScrollUp';
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import EventCard from '../components/EventCard';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';


// Google Maps:
// https://react-google-maps-api-docs.netlify.app/ 
// https://medium.com/@allynak/how-to-use-google-map-api-in-react-app-edb59f64ac9d
// API key: AIzaSyDjD3mbs-9JHCYTvg_Taaj0ilQUmoOYSBU 

//  <Marker position={center} />
// Try Marker or MarkerF


const EventDetails = (event) => {

    const { event_id } = useParams();
    // const [data, setEvents] = useState([]);

    const fetchEvent = async () => {
        const {data}  = await Axios.get("/api/events/"+event_id);
        console.log(data.event);
        console.log(data.events);
        return data;
    };

    const { data, isLoading, error, isError, refetch } = useQuery(["search_event"], fetchEvent);

    // useEffect(() => {
    //     fetch("/api/events/"+event_id)
    //         .then(response => response.json()
    //         .then((data) => {
    //         setEvents(data);
    //         })
    //     );
    //   }, []);


    useEffect(() => {
        refetch();
      }, [event_id]);



    const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    });

    const mapStyles = {        
        height: "65vh",
        width: '800px',
        maxWidth: "100%"
    };  

    const center = {
        lat: data?.event?.latitude,
        lng: data?.event?.longitude
    };

    if (isLoading) {
        return
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color="secondary"/>
            </Box>
            // return <h2>Loading...</h2>
    };

    if (isError) {
        return <h2>Sorry, there was an error</h2>
        // return <h2>Sorry, there was an error: {error.message}</h2>
    };


    return ( 
        <Container sx={{maxWidth: 1100, mb: -28, mt: -4}}>
            <Toolbar id="back-to-top-anchor" />
            <BackToTop/>

            <Paper
                sx={{
                    p: 2,
                    margin: 'auto',
                    width: '100%',
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
                >

                <Grid container spacing={0} sx={{  width: '100%', height: '100%' }}>

                    <Grid item >
                        <ButtonBase >
                            <Img alt="" src={data?.event?.img_link} sx={{  maxWidth: 1,  maxHeight: 525 }}/>
                        </ButtonBase>
                    </Grid>
                    {/* <Grid item xs={12} sm container> */}

                        <Grid item xs container direction="column" spacing={2} marginLeft={0}>

                            <Grid item xs>
                                <Typography gutterBottom variant="h4" component="h2" marginTop={1}>
                                    {data?.event?.event} 
                                </Typography>

                                <Typography variant="h6" marginTop={1} >
                                    <DateTime
                                    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
                                        timeZone="UTC"
                                        locale="en-US"
                                        weekday="short"
                                        month="short"
                                        day="numeric"
                                        hour="2-digit"
                                        minute="numeric"
                                    >
                                        {data?.event?.full_date}
                                    </DateTime>
                                </Typography>

                                <Typography variant="body2" color="text.secondary" marginTop={1}>
                                    {data?.event?.venue}
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    {data?.event?.street_address}, {data?.event?.city} {data?.event?.state}
                                </Typography>
                            </Grid>


                            <Grid item>
                                    {/* <IconButton aria-label="add to favorites">
                                        <FavoriteIcon />
                                    </IconButton> */}
                                {/* <Tooltip title="Share Event">
                                    <IconButton aria-label="share"> */}
                                        {/* <a href="mailto:?subject=Check%20out%20this%20event%20on%20Arttrav3l&body=https://dev.to/dsasse07/beginner-s-guide-to-jest-testing-in-react-1nig%20Check%20out%20this%20new%20article%20on%20Jest%20testing%20in%20React!" target="_blank" rel="noreferrer">Share on Email</a> */}
                                        {/* <ShareIcon />
                                    </IconButton>
                                </Tooltip> */}
                            </Grid>

                        {/* </Grid> */}
                    </Grid>


                </Grid>
            </Paper>



            <Box sx={{ mt: 8, mb: 4 }}>
                <Typography variant="h4" component="h2" marginTop={5} marginBottom={3} color="secondary" >
                    Event details
                </Typography>

                <Typography variant="body2"  marginBottom={3} >
                    {data?.event?.description}
                </Typography>

                <Link href={data?.event?.web_link} target="_blank" rel="noopener" underline="hover" color="text.secondary">Learn more from the organizer</Link>
            </Box>


            <Box sx={{ mb: 10 }}>
                <LoadScript
                    googleMapsApiKey='AIzaSyDjD3mbs-9JHCYTvg_Taaj0ilQUmoOYSBU'>
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom = {14}
                            center = {center}>
                            <MarkerF position={center} />
                        </GoogleMap>   
                </LoadScript>
            </Box>



            <Typography variant="h4" component="h2" marginTop={5} marginBottom={3} >
                You may also like
            </Typography>

            <Grid container rowSpacing={4} columnSpacing={4} >
                {data?.events?.map((event) => <EventCard event={event} key={event.event_id}/>)}
            </Grid>


    </Container>
    );
}

export default EventDetails;