import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Container } from "@mui/material";


export default function SearchAppBar() {
  return (
      <AppBar position="static" >
        <Container maxWidth="lg">
          <Toolbar >
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 12, sm: 'block' } }}
            >
              <Link color="#f44336" underline="none" fontWeight="bold" component={RouterLink}  to="/">
                Arttrav3l
              </Link>{' '}
            </Typography>

            {/* <Button color="inherit">Login</Button> */}

          </Toolbar>
        </Container>
      </AppBar>
  );
}
