import React, {useEffect, useState} from 'react';
import EventCard from '../components/EventCard';
import usePagination from "../components/Pagination";
import BackToTop from '../components/ScrollUp';
import { Typography, createTheme, ThemeProvider } from "@mui/material";
import { Container, Box, Grid, Stack, Pagination } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link as RouterLink, MemoryRouter, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import Input from '@mui/material/Input';
import CssBaseline from '@mui/material/CssBaseline';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CircularProgress from '@mui/material/CircularProgress';
import image from './nyc_buildings.jpg';


const Home = () => {

  const fetchUpcomingEvents = async () => {
    const {data}  = await Axios.get("/api/upcoming_events");
    console.log(data.events);
    return data.events;
  };

  const { data, isLoading, error, isError } = useQuery(["some_query_id"], fetchUpcomingEvents);
// data: otherName to name it somwthing else

  // const [searchParams, setSearchParams] = useSearchParams();
  // const date_value = searchParams.get('date_value');
  // const search_word = searchParams.get('search_word');


  const [date_value, setDate] = useState(null);
  const [search_word, setSearchWord] = useState("");
  const navigate = useNavigate();
  const current_date = new Date();
  const ariaLabel = { 'aria-label': 'description' };
  const [search_error, setSearchError] = useState(false);

  const [ searchParams, setSearchParams] = useSearchParams();
  const source = searchParams.get('source');
  const borough = searchParams.get('borough');
  const [selected_location, setSelectedLocation] = useState(borough);
  const [selected_website, setSelectedWebsite] = useState(source);

  const [filtered_data, setFilteredData] = useState([]);
  let filtered_events ;
  const [no_filtered_results, setNoFilteredResults] = useState(false);



  const handleWebsiteChange = (event) => {
    console.log("website chosen is: ", event.target.value);
    setSelectedWebsite(event.target.value);
    if (event.target.value === 'Eventbrite' || event.target.value === "Seatgeek" || event.target.value === "Meetup") {
      // setSelectedWebsite(event.target.value);

      if (!borough){
        setSearchParams({ source: event.target.value});
        filtered_events = data.filter((e) => {
          return e.website === event.target.value
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for ", event.target.value, " is: ", filtered_events);
      }
      else {
        setSearchParams({ source: event.target.value, borough: selected_location });
        filtered_events = data.filter((e) => {
          return ( e.website === event.target.value && e.borough === selected_location)
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for BOTH: ", filtered_events);
      };
    }

    else {
      // setSelectedWebsite("");
      // setSelectedWebsite(event.target.value);
      // setSearchParams({});
      if ( !borough ){
        setSearchParams({});
        console.log("Any website, any borough");
        setNoFilteredResults(false);
      }
      else {
        setSearchParams({ borough: selected_location });
        filtered_events = data.filter((e) => {
          return ( e.borough === selected_location)
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("Any website, previous location: ", filtered_events);

      };

      // if (searchParams.has('source')) {
      //   searchParams.delete('source');
      // };
      // setNoWebsiteFilter(true);
    };
  };




  const handleLocationChange = (event) => {
    console.log("borough chosen is: ", event.target.value);
    setSelectedLocation(event.target.value);
    if (event.target.value === 'New York' || event.target.value === 'Brooklyn' || event.target.value === 'Queens' || event.target.value === 'Bronx' || event.target.value === 'Staten Island') {
      // setSelectedLocation(event.target.value);  
      // setSearchParams({ borough: event.target.value});

      if (!source){
        setSearchParams({ borough: event.target.value});
        filtered_events = data.filter((e) => {
          return ( e.borough === event.target.value )
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for ", event.target.value, " is: ", filtered_events);
      }
      else {
        setSearchParams({ source: selected_website, borough: event.target.value });
        filtered_events = data.filter((e) => {
          return ( e.website === selected_website && e.borough === event.target.value )
        });
        if (filtered_events.length ===0) {setNoFilteredResults(true)}
        else {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for BOTH: ", filtered_events);
      };
    }


    else {
      // setSelectedLocation("");
      if ( !source ) {
        setSearchParams({});
        console.log("Any website, any borough");
        setNoFilteredResults(false);
      }
      else {
        setSearchParams({ source: selected_website });
        const filtered_events = data.filter((e) => {
          return ( e.website === selected_website)
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("Any borough, previous website: ", filtered_events);
      };

      // // setSearchParams({ borough: null });
      // if (searchParams.has('borough')) {
      //   searchParams.delete('borough');
      // };
      // setNoLocationFilter(true);
    };
  };



  // const handleLocationChange = (event) => {
  //   setSelectedLocation(event.target.value);
  // };

  // const handleWebsiteChange = (event) => {
  //   setSelectedWebsite(event.target.value);
  // };

  const handleSearchWordChange = (event) => {
    setSearchWord(event.target.value);
  };

  const clearSearchWord = () => {
    setSearchWord("");
  };

  const clearDateValue = () => {
    setDate(null);
  };

  const handleSearch = (event) => {
    if (search_word.length || date_value ) {
      setSearchError(false);
      navigate(`/search/${date_value}/${search_word}`);
    }
    else {
      setSearchError(true);
    }
  };


  if (isError) {
    return <h2>Sorry, there was an error: {error.message} </h2>
  }



  return (
    <div className="App"> 
      <Box sx={{ minHeight: 485 }} style={{backgroundImage:`url(${image})`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
        <Container >

        <Typography variant="caption" >
            .
        </Typography>
        <Typography variant="h3" component="h4" marginBottom={4} align="center" >
            Find art events in New York City 
        </Typography>


        <Box sx={{ '& > :not(style)': {m: 4}, border: 1, borderColor: 'grey.200', borderRadius: 1, width: '100%' }} >
            <Stack direction="column" spacing={3}  justifyContent="center" alignItems="center" >
              
              <Stack direction="row" spacing={1}  justifyContent="center" alignItems="center" sx={{width:'100%'}}>
                
                <Box 
                  noValidate 
                  autoComplete="off"
                  sx={{
                    '& > :not(style)': { m: 0 },
                    width: '100%',
                  }}
                >



                  <FormControl sx={{ m: 1, width:'100%', backgroundColor: 'grey.100', borderRadius: 1 }}  variant="standard" >
                    <OutlinedInput
                      value={search_word} 
                      placeholder="Search by keyword, event, artist, venue, or date" 
                      error={search_error}
                      onChange={handleSearchWordChange} 
                      startAdornment={
                        <InputAdornment position="start">
                            {search_word.length ===0 ?  <SearchIcon  /> : <ClearIcon onClick={clearSearchWord}/>}
                        </InputAdornment>
                      }
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //       {search_word.length != 0 && <ClearIcon onClick={clearSearchWord}/> }
                      //   </InputAdornment>
                      // }
                    />
                    {/* <FormHelperText>{error ? "Enter keyword or date to preceed" : ""}</FormHelperText> */}
                  </FormControl>
                </Box>
              
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select date"
                    value={date_value}
                    minDate={current_date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => <TextField sx={{ backgroundColor: 'grey.100', borderRadius: 1 }} {...params} />}   

                    InputProps={{
                      startAdornment: date_value != null && <ClearIcon onClick={clearDateValue}/>,
                    }}
                  />
                </LocalizationProvider>
              </Stack>
      
              <Button 
                sx={{ width: 185, maxWidth: '50%' }} 
                variant="contained" size="large" 
                color="secondary" 
                onClick={handleSearch} >Search
              </Button>
            </Stack>
          </Box>

        </Container>
      </Box>




      <Container sx={{ mb: -29, mt: -2 }}>

        <Toolbar id="back-to-top-anchor" />
        <BackToTop/>

        <Typography variant="h4" component="h2" marginTop={2} marginBottom={2} >
            Events happening soon
        </Typography>


        <Box sx={{ mb: 3 }}>
          <FormControl variant="filled" color="secondary" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">Borough</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selected_location}
              label="Borough"
              defaultValue={''}
              onChange={handleLocationChange}
            >
            <MenuItem value={'Any borough'}>Any borough</MenuItem>
            <MenuItem value={'New York'}>Manhattan</MenuItem>
            <MenuItem value={'Brooklyn'}>Brooklyn</MenuItem>
            <MenuItem value={'Queens'}>Queens</MenuItem>
            <MenuItem value={'Bronx'}>Bronx</MenuItem>
            <MenuItem value={'Staten Island'}>Staten Island</MenuItem>
            </Select>
          </FormControl>


          <FormControl variant="filled" color="secondary" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">Web source</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selected_website}
              label="Web source"
              defaultValue={''}
              onChange={handleWebsiteChange}
            >
            <MenuItem value={'Any website'}>Any web source</MenuItem>
            <MenuItem value={'Seatgeek'}>Seatgeek</MenuItem>
            <MenuItem value={'Eventbrite'}>Eventbrite</MenuItem>
            <MenuItem value={'Meetup'}>Meetup</MenuItem>
            </Select>
          </FormControl>
        </Box>





        <div>
        { isLoading === true ?
          <Box justifyContent="center" sx={{ display: 'flex' }}>
            <CircularProgress color="secondary"/>
          </Box>          
          :

          <div>
          { (data.length === 0 || no_filtered_results) ? <h2>Sorry, nothing matched your search </h2>
          :
            <div>
            { (!source && !borough) ?
              <Grid container rowSpacing={4} columnSpacing={4} >
                {data?.map((event) => <EventCard event={event} key={event.event_id}/>)}
              </Grid>
            :

              <div>
                { (source && borough) ?
                  <Grid container rowSpacing={4} columnSpacing={4} >
                    {data.filter((e) => {return e.website === source && e.borough === borough} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                  </Grid>
                  :
                    <div>
                    { source ?
                      <Grid container rowSpacing={4} columnSpacing={4} >
                        {data.filter((e) => {return e.website === source} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                      </Grid>
                      :
                        <Grid container rowSpacing={4} columnSpacing={4} >
                        {data.filter((e) => {return e.borough === borough} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                        </Grid>
                    }
                    </div>
                }
              </div>

            }
            </div>
          }
          </div> 
        }
      </div>


      </Container>
    </div>
  )
}

export default Home


{/* <div>
{isLoading == true ?
  <Box justifyContent="center" sx={{ display: 'flex' }}>
    <CircularProgress color="secondary"/>
  </Box>  :
  <Grid container rowSpacing={4} columnSpacing={4} >
    {data?.map((event) => <EventCard event={event} key={event.event_id}/>)}
  </Grid>
}    
</div> */}



