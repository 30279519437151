import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link as RouterLink, MemoryRouter } from 'react-router-dom';


function Copyright() {
  return (
    <Typography variant="body2" color="#fafafa" align="center" >
      {'© '}
      {new Date().getFullYear()}
      {' '}
      <Link color="inherit" component={RouterLink} to="/">
        Arttrav3l
      </Link>{' '}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '55vh',
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 1,
          px: 2,
          mt: 'auto',
          backgroundColor: '#1b1b1b',
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1" align="center" color="#fafafa " >
            Built with {' '}
            <FavoriteIcon sx={{width: 15, marginBottom: 0.42, color: '#d84315'}} />
          </Typography>
          <Copyright />
        </Container>
      </Box>

    </Box>
  );
}