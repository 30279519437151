import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import EventDetails from "./pages/EventDetails";
import SearchEvents from "./pages/SearchEvents.js";
import ErrorPage from './pages/ErrorPage';
import SearchAppBar from "./components/AppBar";
import StickyFooter from "./components/StickyFooter";
import ScrollToTop from "./components/ScrollToTop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { indigo, grey, blue, red, orange, deepOrange, brown } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';


// customizing Typography component (font color, size)
// const theme = createTheme({
//     components: {
//         MuiTypography: {
//             variants: [
//                 {
//                     props:{
//                         variant: "body1",
//                     },
//                     style: {
//                         color: '#FF69B4',
//                     },
//                 },
//                 {
//                     props:{
//                         variant: "body2",
//                     },
//                     style: {
//                         fontSize: 13,
//                     },
//                 },
//             ],
//         },
//     },
// });

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      // main: indigo[400],
      // main: grey[10],
      // main: deepOrange[400],
    },
    secondary: {
      main: deepOrange[600],
      // main: indigo[800],
    },
  },

  components: {
    MuiTypography: {
      variants: [
        { props:{ variant: "h3" }, style: { color: '#f44336', fontSize: 59, fontWeight: "bold" }, },
        { props:{ variant: "h4" }, style: { color: '#f44336', fontWeight: "bold" }, }
      ],
      // variants: [{ props:{ variant: "h3" }, style: { color: '#ffffff' }, }],
    },
  },
});



function App() {
  const my_client = new QueryClient({
    defaultOptios: {
      queries:{
        refetchOnWindowsFocus: false,
      },
    }, 
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={my_client}>
        <Router>
          <ScrollToTop />
          <SearchAppBar />

          <Routes>
            <Route path="/events/:event_id" element={<EventDetails />}/>
            <Route path="/search" />
              <Route path="/search/" element={<SearchEvents />}/>
              <Route path="/search/:date_value" element={<SearchEvents />}/>
              <Route path="/search/:search_word" element={<SearchEvents />}/>
              <Route path="/search/:date_value/:search_word" element={<SearchEvents />}/>
            <Route path="/" element={<Home />}/>
            <Route path="*" element={<ErrorPage />} />
          </Routes>

          <StickyFooter />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
