import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const ErrorPage = () => {
    return (
        <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
            <Container maxWidth="lg">
                <Typography variant="h5" align="center" gutterBottom>
                    Error! Page not found
                </Typography>
            </Container>
        </Box>
    );
}

export default ErrorPage;
