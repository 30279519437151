import React, {useEffect, useState} from 'react';
import EventCard from '../components/EventCard';
// import FiltersBoroughWeb from '../components/FiltersBoroughWeb';
import usePagination from "../components/Pagination";
import BackToTop from '../components/ScrollUp';
import { Typography } from "@mui/material";
import { Stack, Grid, Container, Box, Pagination } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link as RouterLink, Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import CircularProgress from '@mui/material/CircularProgress';
import { indigo, grey, blue, red, orange, deepOrange, brown } from '@mui/material/colors';
const ariaLabel = { 'aria-label': 'description' };



const SearchEvents = () => {

  const { date_value } = useParams();
  const { search_word } = useParams();

  const fetchSearchEvents = async () => {
    const {data}  = await Axios.get(`/api/search/?date=${date_value}&s_word=${search_word}`);
    console.log("fetched: ", data.events);
    return data.events;
  };

  const { data, isLoading, error, isError, refetch } = useQuery(["searched_query_id"], fetchSearchEvents);
  

  // let [page, setPage] = useState(1);
  // const eventsPerPage = 48;
  // const count = Math.ceil(data.length / eventsPerPage);
  // const paginated_data = usePagination(data, eventsPerPage);
  // const handlePageChange = ( event, value) => {
  //   setPage(value);
  //   paginated_data.jump(value);
  //   window.scroll(0,0);  // window.scrollTo({ top: 0, behavior: 'smooth' })
  // };


  const [ searchParams, setSearchParams] = useSearchParams();
  const source = searchParams.get('source');
  const borough = searchParams.get('borough');
  const [selected_location, setSelectedLocation] = useState(borough);
  const [selected_website, setSelectedWebsite] = useState(source);


  useEffect(() => {
    refetch();
    setSearchError(false);
    setSelectedWebsite(source);
    setSelectedLocation(borough);
    }, [date_value, search_word]);


  const [selected_date_value, setDate] = useState(date_value);
  const [selected_search_word, setSearchWord] = useState(search_word);
  const [search_error, setSearchError] = useState(false);
  const navigate = useNavigate();
  const current_date = new Date();
  const [filtered_data, setFilteredData] = useState([]);
  let filtered_events ;
  const [no_filtered_results, setNoFilteredResults] = useState(false);



  const handleWebsiteChange = (event) => {
    console.log("website chosen is: ", event.target.value);
    setSelectedWebsite(event.target.value);
    if (event.target.value === 'Eventbrite' || event.target.value === "Seatgeek" || event.target.value === "Meetup") {
      // setSelectedWebsite(event.target.value);

      if (!borough){
        setSearchParams({ source: event.target.value});
        filtered_events = data.filter((e) => {
          return e.website === event.target.value
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for ", event.target.value, " is: ", filtered_events);
      }
      else {
        setSearchParams({ source: event.target.value, borough: selected_location });
        filtered_events = data.filter((e) => {
          return ( e.website === event.target.value && e.borough === selected_location)
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for BOTH: ", filtered_events);
      };
    }

    else {
      // setSelectedWebsite("");
      // setSelectedWebsite(event.target.value);
      // setSearchParams({});
      if ( !borough ){
        setSearchParams({});
        console.log("Any website, any borough");
        setNoFilteredResults(false);
      }
      else {
        setSearchParams({ borough: selected_location });
        filtered_events = data.filter((e) => {
          return ( e.borough === selected_location)
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("Any website, previous location: ", filtered_events);

      };

      // if (searchParams.has('source')) {
      //   searchParams.delete('source');
      // };
      // setNoWebsiteFilter(true);
    };
  };




  const handleLocationChange = (event) => {
    console.log("borough chosen is: ", event.target.value);
    setSelectedLocation(event.target.value);
    if (event.target.value === 'New York' || event.target.value === 'Brooklyn' || event.target.value === 'Queens' || event.target.value === 'Bronx' || event.target.value === 'Staten Island') {
      // setSelectedLocation(event.target.value);  
      // setSearchParams({ borough: event.target.value});

      if (!source){
        setSearchParams({ borough: event.target.value});
        filtered_events = data.filter((e) => {
          return ( e.borough === event.target.value )
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for ", event.target.value, " is: ", filtered_events);
      }
      else {
        setSearchParams({ source: selected_website, borough: event.target.value });
        filtered_events = data.filter((e) => {
          return ( e.website === selected_website && e.borough === event.target.value )
        });
        if (filtered_events.length ===0) {setNoFilteredResults(true)}
        else {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("filtered data for BOTH: ", filtered_events);
      };
    }


    else {
      // setSelectedLocation("");
      if ( !source ) {
        setSearchParams({});
        console.log("Any website, any borough");
        setNoFilteredResults(false);
      }
      else {
        setSearchParams({ source: selected_website });
        const filtered_events = data.filter((e) => {
          return ( e.website === selected_website)
        });
        if (filtered_events.length ===0) 
          {setNoFilteredResults(true)}
        else 
          {setNoFilteredResults(false)};
        // setFilteredData(filtered_events);
        // console.log("Any borough, previous website: ", filtered_events);
      };

      // // setSearchParams({ borough: null });
      // if (searchParams.has('borough')) {
      //   searchParams.delete('borough');
      // };
      // setNoLocationFilter(true);
    };
  };



  const handleSearchWordChange = (event) => {
    setSearchWord(event.target.value);
  };

  // const handleSearch = (event) => {
  //   navigate(`/search/${selected_date_value}/${selected_search_word}`);
  // };

  // const handleSearch = (event) => {
  //   if ( (selected_search_word !== "undefined" || selected_search_word || selected_search_word !== "" ) || (selected_date_value !=="null" || selected_date_value) ) {
  //     navigate(`/search/${selected_date_value}/${selected_search_word}`);
  //   }
  //   else {
  //     setSearchError(true);
  //   }
  // };

  const handleSearch = (event) => {
    if ( (selected_search_word === "undefined" || !selected_search_word || selected_search_word === "" ) && (selected_date_value ==="null" || !selected_date_value) ) {
      setSearchError(true);
    }
    else {
      navigate(`/search/${selected_date_value}/${selected_search_word}`);
    }
  };
 

  const clearSearchWord = () => {
    setSearchWord("");
  };

  const clearDateValue = () => {
    setDate(null);
  };


  // if (isLoading) {
  //   return <CircularProgress color="secondary" />
  //   // <Box sx={{ display: 'flex' }}>
  //   //   <CircularProgress />
  //   // </Box>
  //   // return <h2>Loading...</h2>
  // }

  if (isError) {
    return <h2>Sorry, there was an error </h2>
    // return <h2>An error has occurred: {error.message} </h2>
  };


  // if ( source && !borough ) {
  //   filtered_events = data.filter((e) => {
  //     return e.website === selected_website;
  //   });
  //   setFilteredData(filtered_events);
  //   console.log("filtered data for ", selected_website, " is: ", filtered_events);
  // };






// ******************************************************************************************   
// ******************************************************************************************   
  return (
    <Container sx={{ mb: -29, mt: -3}}>

      <Toolbar id="back-to-top-anchor" />
      <BackToTop/>


      <Box sx={{ '& > :not(style)': {m: 4}, border: 1, borderColor: 'grey.400', borderRadius: 1, width: '100%' }} >
        <Stack direction="column" spacing={3}  justifyContent="center" alignItems="center" >
          
          <Stack direction="row" spacing={1}  justifyContent="center" alignItems="center" sx={{width:'100%'}}>
            
            <Box 
              noValidate 
              autoComplete="off"
              sx={{
                '& > :not(style)': { m: 0 },
                width: '100%',
              }}
            >
              <FormControl sx={{ m: 1, width:'100%', backgroundColor: 'white', borderRadius: 1 }} variant="standard" >
                <OutlinedInput
                  value={selected_search_word} 
                  placeholder="Search by event, artist, venue, keyword, or date" 
                  error={search_error}
                  onChange={handleSearchWordChange} 
                  startAdornment={
                    <InputAdornment position="start">
                        {(selected_search_word === "" || !selected_search_word) ?  <SearchIcon  /> : <ClearIcon onClick={clearSearchWord}/>}
                    </InputAdornment>
                  }
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //       {selected_search_word !=="undefined" && <ClearIcon onClick={clearSearchWord}/> }
                  //   </InputAdornment>
                  // }
                />
              </FormControl>
            </Box>
          
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select date"
                value={selected_date_value}
                minDate={current_date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => <TextField sx={{ backgroundColor: 'white', borderRadius: 1 }} {...params} />}   

                InputProps={{
                  startAdornment: (selected_date_value !== "null" && selected_date_value != null) && <ClearIcon onClick={clearDateValue}/>,
                }}
              />
            </LocalizationProvider>
          </Stack>
  
          <Button 
            sx={{ width: 185, maxWidth: '50%' }} 
            variant="contained" 
            size="large" 
            color="secondary" 
            onClick={handleSearch} >Search
          </Button>
        </Stack>
      </Box>



    
        <Typography variant="h4" component="h2" marginTop={6} marginBottom={2} >
            Search results
            {/* Search results: {date_value} search:{search_word}. Or, source is: {source}. Borough is {borough}. */}
        </Typography>


        <Box sx={{ mb: 3 }}>
          <FormControl variant="filled" color="secondary" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">Borough</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selected_location}
              label="Borough"
              defaultValue={''}
              onChange={handleLocationChange}
            >
            <MenuItem value={'Any borough'}>Any borough</MenuItem>
            <MenuItem value={'New York'}>Manhattan</MenuItem>
            <MenuItem value={'Brooklyn'}>Brooklyn</MenuItem>
            <MenuItem value={'Queens'}>Queens</MenuItem>
            <MenuItem value={'Bronx'}>Bronx</MenuItem>
            <MenuItem value={'Staten Island'}>Staten Island</MenuItem>
            </Select>
          </FormControl>


          <FormControl variant="filled" color="secondary" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">Web source</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selected_website}
              label="Web source"
              defaultValue={''}
              onChange={handleWebsiteChange}
            >
            <MenuItem value={'Any website'}>Any web source</MenuItem>
            <MenuItem value={'Seatgeek'}>Seatgeek</MenuItem>
            <MenuItem value={'Eventbrite'}>Eventbrite</MenuItem>
            <MenuItem value={'Meetup'}>Meetup</MenuItem>
            </Select>
          </FormControl>
        </Box>




      <div>
        { isLoading === true ?
          <Box justifyContent="center" sx={{ display: 'flex' }}>
            <CircularProgress color="secondary"/>
          </Box>          
          :

          <div>
          { (data.length === 0 || no_filtered_results) ? <h2>Sorry, nothing matched your search </h2>
          :
            <div>
            { (!source && !borough) ?
              <Grid container rowSpacing={4} columnSpacing={4} >
                {data?.map((event) => <EventCard event={event} key={event.event_id}/>)}
              </Grid>
            :

              <div>
                { (source && borough) ?
                  <Grid container rowSpacing={4} columnSpacing={4} >
                    {data.filter((e) => {return e.website === source && e.borough === borough} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                  </Grid>
                  :
                    <div>
                    { source ?
                      <Grid container rowSpacing={4} columnSpacing={4} >
                        {data.filter((e) => {return e.website === source} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                      </Grid>
                      :
                        <Grid container rowSpacing={4} columnSpacing={4} >
                        {data.filter((e) => {return e.borough === borough} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                        </Grid>
                    }
                    </div>
                }
              </div>

            }
            </div>
          }
          </div> 
        }
      </div>


      {/* <Stack spacing={2} marginTop={10} alignItems="center">
        <Pagination 
            color="secondary" 
            shape="rounded" 
            count={count} 
            page={page} 
            onChange={handlePageChange} 
          />
      </Stack>   */}
        

    </Container>
  );
}

export default SearchEvents;







{/* <div>
{ isLoading === true ?
  <Box justifyContent="center" sx={{ display: 'flex' }}>
    <CircularProgress color="secondary"/>
  </Box>          
  :

  <div>
  { data.length === 0 ? <h2>Sorry, nothing matched your search </h2>
  :
    <div>
    { (!source && !borough) ?
      <Grid container rowSpacing={4} columnSpacing={4} >
        {data?.map((event) => <EventCard event={event} key={event.event_id}/>)}
      </Grid>
    :

      <div>
        { (source && borough) ?
          <Grid container rowSpacing={4} columnSpacing={4} >
            {data.filter((e) => {return e.website === source && e.borough === borough} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
          </Grid>
          :
            <div>
            { source ?
              <Grid container rowSpacing={4} columnSpacing={4} >
                {data.filter((e) => {return e.website === source} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
              </Grid>
              :
                <Grid container rowSpacing={4} columnSpacing={4} >
                {data.filter((e) => {return e.borough === borough} )?.map((event) => <EventCard event={event} key={event.event_id}/>)}
                </Grid>
            }
            </div>
        }
      </div>

    }
    </div>
  }
  </div> 
}
</div> */}



//-----------------------


//   let [page, setPage] = useState(1);
//   const eventsPerPage = 48;
//   const count = Math.ceil(data.length / eventsPerPage);
//   const paginated_data = usePagination(data, eventsPerPage);
//   const handlePageChange = ( event, value) => {
//     setPage(value);
//     paginated_data.jump(value);
//     window.scroll(0,0);  // window.scrollTo({ top: 0, behavior: 'smooth' })
// };



{/* <Stack spacing={2} marginTop={10} alignItems="center">
<Pagination 
    color="secondary" 
    shape="rounded" 
    count={count} 
    page={page} 
    onChange={handlePageChange} 
  />
</Stack>  */}
