import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { Button, CardActionArea, CardActions } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from "@mui/material";
import { DateTime, IntlProvider } from "react-intl-datetime-format";
import { Link as RouterLink, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


const EventCard = ({event}) => {

    return (
        // xs=4 to have 3 cards per row (12/cards per row); xs=6 for 2 card per row
        <Grid item xs={6} sm={4} md={3}>

            <Card style={{ height: '100%' }}>
                <CardActionArea component={RouterLink} to={ `/events/${event.event_id}` }>
                    <CardMedia
                        component="img"
                        height="180"
                        src={event.img_link}
                        alt=""
                    />
                    <CardContent >
                        <Typography gutterBottom variant="h6" component="div">
                            {event.event}
                        </Typography>

                        <Typography variant="body1" marginTop={3} color="secondary" >
                            <DateTime
                            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
                                timeZone="UTC"
                                locale="en-US"
                                weekday="short"
                                month="short"
                                day="numeric"
                                hour="2-digit"
                                minute="numeric"
                            >
                                {event?.full_date}
                            </DateTime>
                        </Typography>

                        <Typography variant="body2" marginTop={1} >
                            {event.venue} - {event.borough}, {event.state}
                        </Typography>
                    </CardContent>
                </CardActionArea>

                {/* <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <Tooltip title="Share">
                        <IconButton aria-label="share">
                            <ShareIcon />
                            <a href="mailto:?subject=Check%20out%20this%20event%20on%20Arttrav3l&body=https://dev.to/dsasse07/beginner-s-guide-to-jest-testing-in-react-1nig%20Check%20out%20this%20new%20article%20on%20Jest%20testing%20in%20React!" target="_blank" rel="noreferrer">Share on Email</a>
                        </IconButton>
                    </Tooltip>
                </CardActions> */}

            </Card>
        </Grid>
    );
};

export default EventCard;